import React from 'react'
import { markdownify } from '../../utils'
import styles from './DaoBanner.module.css'

export default function DaoBanner({
  round,
  grants,
  submit,
  vote,
  image
}: {
  round: string
  grants: string
  submit: string
  vote: string
  image: string
}) {
  return (
    <div className={styles.overview}>
      <div>
        <div className={styles.round}>{markdownify(round)}</div>
        <div className={styles.grants}>{markdownify(grants)}</div>
        <div className={styles.details}>{markdownify(submit)}</div>
        <div className={styles.details}>{markdownify(vote)}</div>
      </div>
      <div className={styles.imageWrapper}>
        {image && <img className={styles.image} src={image} />}
      </div>
    </div>
  )
}
