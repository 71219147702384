import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'
import Graphic from '../components/pages/technology/Marketplaces/Graphic'
import Overview from '../components/templates/PageTechnology/Overview'
import Details from '../components/templates/PageTechnology/Details'
import SectionResources from '../components/organisms/SectionResources'
import SectionProposals from '../components/pages/dao/SectionProposals'
import Badge from '../components/atoms/Badge'
import styles from '../components/templates/PageTechnology/index.module.css'
import Section from '../components/molecules/Section'

export default function PageGatsbyDao(props: PageProps) {
  const {
    feature,
    badge,
    title,
    description,
    image,
    resources,
    overview,
    details,
    graphicMarketplace
  } = (props.data as any).dao.edges[0].node.childDaoJson

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroCenter
      heroLabel={
        badge ? (
          <>
            <Badge className={styles.badge} label={badge} /> {feature}
          </>
        ) : (
          feature
        )
      }
      heroContent={
        graphicMarketplace ? <Graphic content={graphicMarketplace} /> : null
      }
      heroActions={overview.actions}
      uniformHeroActionButtons
    >
      <>
        <SectionProposals />
      </>
      <Section
        title={overview.title}
        headerCenter
        className={styles.DAOInfoSection}
      >
        {overview && (
          <Overview
            overview={overview}
            image={
              image && image.childImageSharp && image.childImageSharp.fluid
            }
          />
        )}
      </Section>
      {details && <Details details={details} />}
      {resources && (
        <SectionResources
          title={`${feature} Resources`}
          resources={resources}
        />
      )}
    </Layout>
  )
}

// From pageQuery @ src/components/templates/PageTechnology/index.tsx
export const contentQuery = graphql`
  query DaoPageQuery {
    dao: allFile(filter: { relativePath: { eq: "pages/dao/dao.json" } }) {
      edges {
        node {
          childDaoJson {
            title
            description
            feature
            image {
              childImageSharp {
                fluid(maxWidth: 506) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            overview {
              text
              list
              title
              actions {
                title
                description
                url
              }
            }
            details {
              title
              description
              items {
                title
                description
              }
            }
            resources {
              prominent {
                title
                description
                url
              }
              links {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`
