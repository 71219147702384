import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Section from '../../molecules/Section'
import styles from './SectionProposals.module.css'
import { markdownify } from '../../../utils'
import { LinkList } from '../../atoms/Lists'
import ButtonActions from '../../molecules/ButtonActions'
import DaoBanner from '../../molecules/DaoBanner'

const query = graphql`
  query SectionProposalsQuery {
    proposals: allFile(
      filter: { relativePath: { eq: "pages/dao/proposals.json" } }
    ) {
      edges {
        node {
          childDaoJson {
            id
            title
            subTitle
            image {
              publicURL
            }
            buildersTitle
            buildersId
            buildersDescription
            votersTitle
            votersDescription
            daoBanner {
              round
              grants
              submit
              vote
            }
            register {
              title
              id
              description
              actions {
                title
                url
              }
            }
            vote {
              title
              id
              description
              items {
                title
                url
              }
            }
            actions {
              title
              description
              url
            }
          }
        }
      }
    }
  }
`

export default function SectionProposals() {
  const data = useStaticQuery(query)
  const content = data.proposals.edges[0].node.childDaoJson

  return (
    <Section
      id={content.id}
      title={content.title}
      subTitle={content.subTitle}
      className={styles.fundingRounds}
    >
      <section className={`${styles.header}`}>
        {content && (
          <DaoBanner
            round={content.daoBanner.round}
            grants={content.daoBanner.grants}
            submit={content.daoBanner.submit}
            vote={content.daoBanner.vote}
            image={content.image.publicURL}
          />
        )}
      </section>
      <section className={`${styles.header}`}>
        <div id={content.buildersId}>
          <h3>{content.buildersTitle}</h3>
          <div>{markdownify(content.buildersDescription)}</div>
        </div>
      </section>

      <div className={styles.cta}>
        <ButtonActions actions={content.actions} />
      </div>

      <section className={`${styles.header}`}>
        <div>
          <h3>{content.votersTitle}</h3>
          <div>{markdownify(content.votersDescription)}</div>
        </div>
      </section>

      <div className={styles.grid} id={content.register.id}>
        <div className={styles.register}>
          <h3>{content.register.title}</h3>
          <div>{markdownify(content.register.description)}</div>
          <LinkList links={content.register.actions} />
        </div>
        <div className={styles.vote} id={content.vote.id}>
          <h3>{content.vote.title}</h3>
          <div>{markdownify(content.vote.description)}</div>
          <LinkList links={content.vote.items} />
        </div>
      </div>
    </Section>
  )
}
